import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicos-hctc',
  templateUrl: './servicos-hctc.component.html',
  styleUrls: ['./servicos-hctc.component.scss']
})
export class ServicosHctcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

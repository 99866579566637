<section class="project-start-area ptb-70">
  <div class="container">
    <div class="row align-self-center">
      <div class="section-title">
        <img src="assets/img/logo_ctc_f.png" style="width: 150px;" alt="image">
      </div>
    </div>
    <div class="row align-self-center">
      <div class="courses-overview">
        <h3 class="text-center">SEJA MUITO BEM-VINDO(A) AO HOTEL CTC!</h3>
        <p style="text-align: justify-all !important;">Apresentamos nesse diretório as principais informações sobre o
          hotel, seus serviços e regras gerais.
          <strong class="text-danger">Recomendamos sua leitura</strong> e em caso de dúvida, procure a recepção ou
          gerência do hotel.
        </p>

        <h3 class="text-center">VOLTAGEM NO HOTEL: 220 VOLTS</h3>

        <h5 class="mt-5">SEGURANÇA</h5>
        <!-- <div class="content"> -->
        <ul style="font-weight: 600 !important;">
          <li>Não deixe pertences soltos ou sem supervisão na recepção, parque aquático, restaurantes e demais áreas
            comuns e dependências do hotel e clube.</li>
          <li>Visando sua privacidade, não dispomos de câmeras nas piscinas.</li>
          <li>Não aceite ajuda ou propostas de estranhos, informe-se sempre com as equipes do hotel.</li>
          <li>Não se afaste de sua bagagem durante o check in ou check out.</li>
          <li>Ao sair do apartamento, certifique-se de que janelas e porta estejam fechadas.</li>
        </ul>
        <!-- </div> -->

        <p><strong>COFRE:</strong> Disponível gratuitamente nos apartamentos. O hotel não se responsabiliza por objetos
          de valor, joias, dinheiro etc. mantidos no apartamento fora do cofre. Pela mesma razão, não se responsabiliza
          por perdas, extravios, furtos ou quaisquer outros sinistros que porventura possam ocorrer nas áreas sociais e
          dependências do hotel e clube.

        <p class="mx-4"><strong>COMO CONFIGURAR O COFRE</strong><br />
          1) Abra a porta do cofre.<br />
          2) Encontre o botão vermelho próximo à dobradiça.<br />
          3) Pressione o botão por 3 segundos e solte, você ouvirá 2 “bip”.<br />
          4) Escolha uma senha de 3 a 6 dígitos e pressione #.<br />
          5) Haverá outro “bip”, sua senha está configurada.<br />
          <img src="../../assets/img/img-cofre-01.png" width="250px">
        </p>


        <p class="mx-4 text-danger"><strong>PARA FECHAR O COFRE, basta girar a tranca no sentido anti anti
            horário.</strong><br />
          <img src="../../assets/img/img-cofre-02.png" width="250px">
        </p>

        <p class="mx-4 text-primary"><strong>PARA ABRIR O COFRE</strong><br />
          1) Digite a senha programada e pressione #. Exemplo: senha 12345#<br />
          2) Gire a tranca no sentido horário e puxe a porta.<br />
          <img src="../../assets/img/img-cofre-03.png" width="250px">
        </p>

        <p class="mx-4"><strong>ATENÇÃO:</strong><br />
          - Ao ir embora (check out) deixe a porta do cofre ABERTA.<br />
          - Ao utilizar o cofre, sempre confira se a porta está trancada.<br />
          - Caso esqueça a senha, ou seja necessário abertura mecânica, será cobrado uma taxa.<br />
        </p>

        <p class="mt-5"><strong>EMERGÊNCIAS MÉDICAS:</strong>: Por segurança, a recepção preferencialmente deve ser acionada, pois
          possui os contatos
          de profissionais capacitados* a atender às mais variadas necessidades.<br />* Em caso de utilização, o serviço
          é particular correndo as despesas por conta do hóspede. <br />
          <strong>Sistema público de emergência: 192 SAMU / 193 Corpo de Bombeiros / 190 Polícia</strong>
        </p>

        <h5 class="mt-5">HOSPEDAGEM</h5>
        <p><strong>Recepção:</strong> atendimento 24 horas – Ramais 200 ou 9</p>
        <p><strong>Horários:</strong> Entrada à partir das 12:00 e Saída até 10:00.
          Saída tardia (late check out): verificar valores e condições com a recepção. Saídas tardias, se não
          informadas, estão passíveis de cobranças adicionais.
        </p>
        <!-- <div class="content"> -->
        <ul style="font-weight: 600 !important;">
          <li>Não é permitida a hospedagem de animais de nenhum tipo, espécie ou porte.</li>
          <li>Exigimos o cumprimento do horário de silêncio das 22:00 às 8:00 diariamente em todas as áreas do hotel.
          </li>
          <li>Não é permitido estender ou pendurar roupas, toalhas, bandeiras ou quaisquer outros artigos nas janelas
            dos apartamentos.</li>
          <li>Não é permitido o uso, porte ou consumo de qualquer tipo de droga na estrutura do hotel e parque
            aquático. Não hesitaremos em acionar a força policial.</li>
          <li>Não é permitido o uso de equipamentos sonoros nos apartamentos que venham a perturbar outros hóspedes.
          </li>
          <li>É autorizado à gerência do hotel ou seu preposto, entrar em qualquer apartamento, com ou sem a presença
            do hóspede, desde que haja justificativa para tal.</li>
        </ul>
        <!-- </div> -->
        <p><strong>Chaves:</strong> As chaves no CTC são mecânicas, pedimos para que sejam deixadas sempre na recepção,
          pois havendo perda ou extravio, o valor do chaveiro e troca de miolo serão cobrados do hóspede. Em caso de
          perda, comunique
          imediatamente à recepção pois não nos responsabilizamos por acessos desconhecidos e/ou não autorizados ao
          apartamento, nem suas consequências.</p>

        <p><strong>Cartão de Consumo:</strong> Ao hospedar-se, o hóspede recebe um cartão para realizar consumos extras
          nos pontos de venda do hotel, os quais serão pagos na saída (check out). Em caso de perda, comunique
          imediatamente a recepção pois
          consumos indevidos realizados nessas condições não serão estornados. Lembre-se também de sempre conferir o
          cupom de venda para evitar trocas de cartões.</p>

        <p><strong>Formas de pagamento:</strong> Aceitamos pagamentos em espécie, pix, cartões de crédito e cartões de
          débito. <strong>Não aceitamos cheques como forma de pagamento.</strong></p>

        <p><strong>Maleiro e Assistência com Bagagem:</strong> Disponível gratuitamente para os hóspedes. Solicite na
          recepção.</p>

        <p><strong>Estacionamento:</strong> O hotel dispõe de dois bolsões de estacionamento, um no hotel e um segundo
          no clube, com acesso pela rua lateral. É liberado um veículo por apartamento. Não dispomos de serviço de
          manobrista bem como por ser uma cortesia, o hotel não se responsabiliza por danos, avarias, furtos e roubos em
          veículos, no interior ou exterior.</p>

        <p><strong>Frigobar:</strong> Para sua comodidade, o frigobar encontra-se vazio e itens podem ser adquiridos
          nos bares e lanchonetes. </p>

        <div
          style="border-width: 4px; border-color: red; border-style: dashed; margin-top: 4px; margin-bottom: 8px; padding: 5px;">
          <p>De acordo com termo de ajustamento de conduta – TAC – firmado com o ministério público de Goiás e
            Procon Caldas Novas, é proibido o ingresso de alimentos e bebidas trazidos de fora do estabelecimento,
            ressalvados aqueles que se destinem a alimentação infantil ou com comprovada orientação médica.
            <strong>Não há exceção para bebidas alcóolicas e gelo.</strong>
          </p>
        </div>

        <p class="mt-4"><strong>Ar Condicionado:</strong> Todos os apartamentos são equipados com ar condicionado, cada
          aparelho
          possui seu controle que é de responsabilidade do usuário. O extravio, dano ou quebra será cobrado do hóspede
          no check out.</p>

        <p><strong>Programação:</strong></p>
        <!-- <div class="content"> -->
        <ul style="font-weight: 600 !important;">
          <li>Ligue o aparelho pelo controle remoto.</li>
          <li>No botão MODO selecione o tipo de funcionamento, recomendamos a função AUTO (ou um triangulo) onde
            atingida a temperatura desejada, o equipamento a manterá no ambiente;
          </li>
          <li>Lembre-se, quanto MENOR a temperatura (número demonstrado no controle), MAIS FRIO será o ambiente.</li>
        </ul>
        <!-- </div> -->

        <h3 class="mt-5 mb-5 text-danger">AO SAIR DO APARTAMENTO, POR FAVOR DESLIGUE-O.</h3>

        <p><strong>Internet Wi-Fi:</strong> Disponível gratuitamente em todo o hotel. Localize a rede <strong>CTC -
            Wi-Fi</strong>, preencha o formulário
          e a conexão será liberada. Em caso de problemas com a conexão, consulte a recepção. </p>

        <p><strong>Telefonia:</strong><br />
          <strong>Ligações Locais:</strong> 0 + número local*<br />
          <strong>Ligações Interurbanas:</strong> 0+014+DDD+ número*<br />
          <strong>Ligações Internacionais:</strong> 0+0021+código do país + código da cidade+ número*<br />
          <strong>Ligações a </strong>Cobrar: 0+9+014+DDD+número**<br />
          <strong>Ligações 0800:</strong> 0 + 0800 + número**<br />
          *Ligações são tarifadas como consumo extra.
        </p>

        <p><strong>TV:</strong><br />
          <strong>Aberta</strong><br />
          15 Globo<br />
          25 TV Gazeta<br />
          17 Band<br />
          27 Record News<br />
          19 Rede TV<br />
          29 TV Record<br />
          21 Redevida<br />
          31 Agrocanal<br />
          23 Canal do Boi<br />
          33 SBT
        </p>

        <p><strong>Cabo</strong>
          36 Discovery Kids<br />
          49 HBO Family<br />
          40 Telecine<br />
          51 Cartoon Network<br />
          42 SporTV*<br />
          55 Megapix<br />
          46 Multishow
        </p>
        <p>* O Canal 42 pode ser alterado sem aviso prévio para a transmissão de jogos de futebol de relevância
          regional, nacional ou internacional.<br />
          Cada TV possui seu controle que é de responsabilidade do usuário. O extravio, dano ou quebra será cobrado do
          hóspede no check out.</p>

        <p><strong>Governança:</strong> Ramal 536<br />
          A arrumação normal dos apartamentos pode ser feita até às 18h, após este horário, somente retirada de lixo e
          troca de toalhas até as 22:00.</p>
        <p>Visando um posicionamento sustentável e ecológico, realizamos a troca do enxoval a cada dois dias. No caso de
          toalhas, desejando que sejam substituídas antes deste período, pedimos que as deixe no chão. Colabore
          conosco!</p>
        <p>Desejando privacidade, utilize o aviso de “NÃO PERTUBE” colocando-o na maçaneta do lado de fora da
          porta.
        </p>

        <p><strong>CUIDADO:</strong> tinturas de cabelo, alimentos, alguns produtos de beleza e maquiagens, graxa de
          sapato e
          principalmente
          tatuagens de Henna (entre outros produtos), causam manchas definitivas no enxoval inviabilizando seu uso,
          neste caso, as perdas serão cobradas do cliente conforme tabela vigente.</p>

        <p><strong>Lavanderia e passadoria:</strong> De 09:00 às 18:00 de segunda à sábado. Solicite o serviço e valores
          à governança ou
          recepção. É proibido o uso de ferros de passar nos apartamentos, danos a enxovais, colchões ou mobiliário
          serão cobrados.</p>

        <p><strong>Achados e perdidos:</strong> Nosso departamento de governança mantém os objetos encontrados por um
          período de 60
          (sessenta) dias a contar da data em que foram encontrados e podem ser retirados pessoalmente, por terceiros ou
          enviados pelo correio correndo a despesa por conta do hóspede. Após este período, os itens serão descartados
          ou doados, a critério do hotel.</p>

        <h5 class="mt-5">ALIMENTAÇÃO</h5>

        <p><strong>Horários:</strong><br />
          Café da Manhã de 07:00 às 09:30<br />
          Almoço de 11:30 às 14:30<br />
          Jantar 19:00 às 21:30</p>

        <!-- <div class="content"> -->
        <ul style="font-weight: 600 !important;">
          <li>Os serviços de buffet podem ser substituídos por a la carte ou outro serviço sem aviso prévio.</li>
          <li>No restaurante, conforme orientação da vigilância sanitária, não é permitida a entrada descalço,
            molhado, sem
            camisa e/ou em trajes de banho.</li>
          <li>Pensões eventualmente não consumidas durante a hospedagem, não serão repostas ou restituídas.</li>
        </ul>
        <!-- </div> -->

        <p><strong>Bar piscina</strong> localizado nas piscinas do hotel das 08:00 às 00:00</p>

        <p><strong>Bar III</strong> localizado no parque aquático das 08:00 às 20:00</p>

        <p><strong>Butekos</strong> localizado no parque aquático das 10:00 às 18:00</p>

        <p class="mb-5">Demais pontos de venda são parceiros terceirizados, maiores informações no local.</p>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="row align-self-center">
      <div class="section-title">
        <img src="assets/img/metropolitan.png" style="width: 200px;" alt="image">
      </div>
      <div class="courses-overview">

        <h5 class="mt-0">LAZER E PARQUE AQUÁTICO</h5>
        <p>Nossa equipe de recreação oferece diariamente uma programação de atividades nas piscinas do hotel e no parque
          aquático. A programação estará disposta na recepção e pode sofrer alterações por condições climáticas e/ou
          quórum na atividade. </p>
        <p>Nosso parque aquático dispõe de piscinas com água termal mineral, naturalmente quentes, não possuímos sistema
          de aquecimento artificial, por esta razão, sua temperatura varia de acordo com as condições climáticas e a
          hora do dia.</p>

        <p><strong>Piscinas e Parque Aquático:</strong> funcionam das 06:00 às 22:00<br />
          <strong>Parque Aquático Petit Paris:</strong> Funciona das 8:00 às 18:00
        </p>

        <!-- <div class="content"> -->
        <ul style="font-weight: 600 !important;">
          <li>Semanalmente, mediante a ocupação, qualquer área inclusive o Petit Paris, pode estar fechado para
            manutenção preventiva e limpeza.</li>
          <li>Visando a qualidade e segurança de nossas águas, nossas equipes estão autorizadas a esvaziar qualquer
            piscinas para higienização sem aviso prévio.</li>
          <li>Os toboáguas podem ter sua atividade alterada ou suspensa de acordo com necessidade justificada.</li>
          <li>Apesar de dispormos de salva vidas, conforme orientação do corpo de bombeiros, é exigido que as crianças
            estejam acompanhadas pelos pais ou maior responsável durante o uso das piscinas.</li>
          <li>Após 22:00, a utilização das piscinas por parte dos hóspedes é autorizada, porém condicionada a
            disponibilidade de piscina e ao comportamento durante o uso. Hóspedes podem ser convidados a retirar-se em
            caso de excessos, desacato às solicitações de funcionários ou mal uso.</li>
          <li>Não é permitido o consumo de itens adquiridos fora do hotel/clube nas áreas comuns e parque aquático.
          </li>
          <div
            style="border-width: 4px; border-color: red; border-style: dashed; margin-top: 4px; margin-bottom: 8px; padding: 5px;">
            <p>De acordo com termo de ajustamento de conduta – TAC – firmado com o ministério público de Goiás e
              Procon Caldas Novas, é proibido o ingresso de alimentos e bebidas trazidos de fora do estabelecimento,
              ressalvados aqueles que se destinem a alimentação infantil ou com comprovada orientação médica.
              <strong>Não há
                exceção para bebidas alcóolicas e gelo.</strong>
            </p>
          </div>
          <li>Não é permitido o uso de equipamentos sonoros individuais, de qualquer tamanho, modelo ou potência, no
            parque aquático.</li>
          <li>Não é permitido o consumo de alimentos no interior das piscinas.</li>
          <li>Não é permitido o uso de Nargilés, cigarros elétricos ou qualquer artigo fumígeno no interior das
            piscinas, áreas comuns, apartamentos ou áreas fechadas.</li>
          <li>Não é permitido o uso de boias que atrapalhem a utilização normal das piscinas.</li>
          <li>Brincadeiras com bolas, boias e armas d’água são autorizadas desde que não causem incômodo ou risco
            para
            os demais usuários, situações em que o cliente será advertido pelos salva vidas.</li>
          <li>É terminantemente proibido saltos, mergulhos, malabarismos ou qualquer atitude que represente risco ao
            próprio usuário e/ou demais usuários nas piscinas ou entorno delas, situações em que o cliente será
            advertido pelos salva vidas.</li>
          <li>É proibido o uso de produtos como clareadores de pelos, água oxigenada e óleos de bronzear nas
            piscinas.
            Solicitamos que utilize as duchas antes de utilizá-las.</li>
          <li>Não é permitido o uso de trajes inapropriados para o uso das piscinas, tais como camisetas, shorts e
            bermudas de algodão ou jeans</li>
        </ul>
        <!-- </div> -->
        <p><strong>Sauna do hotel:</strong> Dispomos de sauna úmida nas piscinas do hotel e clube.<br />
          <strong>Hotel:</strong> 06:00 às 11:00 e das 15:00 às 19:00<br />
          <strong>Clube:</strong> 06:00 às 16:00<br />
          Regras e condições de uso no local.
        </p>

        <p><strong>Academia:</strong> Dispomos de academia ao ar livre das 06:00 às 22:00, localizado no parque
          aquático.</p>

        <p><strong>Copa da Mamãe:</strong> Localizada no Bar Piscina.</p>

        <p><strong>Massagem:</strong> Dispomos de dois serviços terceirizados de massagem, um na recepção, próximo ao
          restaurante que oferece massagem com equipamentos e outro na entrada principal do clube, oferecendo
          massagistas especializados
          em técnicas orientais. Valores e informações no local.</p>

        <p><strong>Semi joias:</strong> Oferecemos na recepção a M1 Joias, que oferece uma grande variedade de joias,
          semi joias e relógios entre outros artigos. Valores e informações no local.</p>


        <h3 class="text-center mt-5">“Ao hospedar-se, o cliente concorda tacitamente com o cumprimento das condições,
          normas e regras aqui dispostas. Quaisquer situações especiais não contempladas neste diretório podem ser
          discutidas
          diretamente na Gerência do Hotel.”</h3>
      </div>
    </div>

    <div class="row align-self-center">
      <div class="section-title mb-1 mt-5">
        <img src="assets/img/logo_ctc_f.png" style="width: 200px;" alt="image">
      </div>
      <div class="courses-overview">
        <p style="text-align: center !important;">Avenida Orcalino Santos, nº219 – Centro<br />
          Caldas Novas, Goiás CEP: 75.680-013<br />
          Tel.: (64) 3453-1515 (Reservas, atendimento e whatsapp)<br />
          <a href="http://www.hotelctc.com.br" target="_blank">www.hotelctc.com.br</a><br />
        </p>
      </div>
    </div>
  </div>
</section>

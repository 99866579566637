import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicosHctcComponent } from './servicos-hctc/servicos-hctc.component';

const routes: Routes = [

  // Rádio Hotel CTC
  { path: '', component: ServicosHctcComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
